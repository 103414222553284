import { firstValueFrom, map } from 'rxjs';

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseApi } from '@ministrary/shared/apis/base.api';
import { iUser } from '@ministrary/shared/interfaces/user.interface';

import { eFamilyMemberStatus } from '../enums/family-member-status.enum';
import { iFamilyMember } from '../interfaces/family-member.interface';

@Injectable({
  providedIn: 'root'
})
export class FamilyMembersApi extends BaseApi<iFamilyMember> {
  constructor() {
    super('family_members', environment.apiUrl);
  }

  async getActiveFamilyByUserId() {
    const { data } = await this.select('family!family_members_family_id_fkey(*)').eq('user_id', this.userId).eq('status', eFamilyMemberStatus.ACCEPTED).limit(1).single();

    return (data as unknown as iFamilyMember)?.family;
  }

  async loadFamilyMembers(familyId?: string): Promise<iFamilyMember[]> {
    if (!familyId) return [];

    const familyMembersSelect = '*, family(id, name, users!family_owner_fkey(id)), users!family_members_user_id_fkey(id, full_name, email, avatar_url)';
    const { data } = await this.select(familyMembersSelect).eq('family_id', familyId).neq('status', eFamilyMemberStatus.REJECTED);
    return (data as unknown as iFamilyMember[]) || [];
  }

  invite(email: string, full_name?: string) {
    return firstValueFrom(this.http.post<{ data: { user: iUser } }>(`${this.apiUrl}/users/invite/${email}`, { full_name }).pipe(map(({ data }) => data?.user)));
  }

  async checkInvitation() {
    const { data } = await this.select('family(id, name, users!family_owner_fkey(full_name, avatar_url))').eq('user_id', this.userId).eq('status', eFamilyMemberStatus.PENDING).maybeSingle();
    return (data as unknown as iFamilyMember)?.family;
  }

  async answerInvitation(familyId: string, status: eFamilyMemberStatus) {
    const { data } = await this.update({ status, user_id: this.userId }).eq('user_id', this.userId).eq('family_id', familyId).maybeSingle();
    return data as unknown as iFamilyMember;
  }
}
